/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { FaqItemProp } from '@/types';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';

interface IFaqAccordionProps {
  data: FaqItemProp;
}

const FaqAccordion: React.FC<IFaqAccordionProps> = ({ data }) => {
  const [containerHeight, setContainerHeight] = useState(`0px`);
  const [expanded, setExpanded] = useState(false);

  const sectionRef = useRef(null);
  const contentRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    const header = headerRef.current.getBoundingClientRect();
    setContainerHeight(`${header.height}px`);
  }, []);

  function toggleAccordion(e) {
    e.preventDefault();
    setExpanded((_state) => !_state);

    const header = headerRef.current.getBoundingClientRect();
    setContainerHeight(
      expanded ? `${header.height}px` : `${sectionRef.current.scrollHeight}px`,
    );
  }

  return (
    <section
      ref={sectionRef}
      className={`faq ${expanded ? `collapse` : ``}`}
      style={{ maxHeight: `${containerHeight}` }}
      {...(expanded ? { expanded: `` } : {})}
    >
      <div
        ref={headerRef}
        onClick={toggleAccordion}
        role="button"
        tabIndex={0}
        className="h2 question extra-body i-amphtml-accordion-header"
        id="faqs-accordion_AMP_header_0"
        aria-controls="faqs-accordion_AMP_content_0"
        aria-expanded="false"
      >
        {data.question}
      </div>
      <div
        ref={contentRef}
        className="answer i-amphtml-accordion-content"
        id="faqs-accordion_AMP_content_0"
        aria-labelledby="faqs-accordion_AMP_header_0"
        role="region"
      >
        <ContentfulRawToComponent raw={data.answer.raw} />
      </div>
    </section>
  );
};

export default FaqAccordion;
