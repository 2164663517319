/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import { graphql } from 'gatsby';
import CareersSingleStyled from '@/components/styled/CareersSingleStyled';
import FaqAccordion from '@/app/careers/FaqAccordion';
import { IContentfulPositionProps } from '@/types';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import ApplicationForm from '@/app/careers/ApplicationForm';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

const ContentfulCase: React.FC<IContentfulPositionProps> = ({ data }) => (
  <CareersSingleStyled>
    {usePageMeta(data.contentfulPosition?.meta)}
    <div className="page-title container position-page-title" />
    <div className="job-application">
      <Reveal className="intro">
        <div className="container">
          <span className="h4 location">
            {data.contentfulPosition.location}
          </span>
          <h1 className="h2 position">{data.contentfulPosition.title}</h1>
        </div>
      </Reveal>

      <Reveal className="faqs">
        <div className="container">
          <div
            id="faqs-accordion"
            className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
          >
            {data.allContentfulFaq.nodes.map((_faqData) => (
              <FaqAccordion key={_faqData.id} data={_faqData} />
            ))}
          </div>
        </div>
      </Reveal>

      <Reveal className="details">
        <div className="container">
          <div className="row">
            <div className="col-2 col-t-1 col-m-1 job-description-container">
              <h2 className="job-description extra-body">Job description</h2>
            </div>
            <div className="col-2 col-t-1 col-m-1 col-apply">
              <a className="link application" href="#application-form">
                Application
              </a>
            </div>
          </div>
          <div className="more-job-description">
            <ContentfulRawToComponent
              raw={data.contentfulPosition.content.raw}
            />
          </div>
          <span className="more-info">
            More info:&nbsp;
            <a href="mailto:john@iona.studio">john@iona.studio</a>,{` `}
            <a href="mailto:mikko@iona.studio">mikko@iona.studio</a>
          </span>
        </div>
      </Reveal>
      <ApplicationForm position={data.contentfulPosition} />
    </div>
  </CareersSingleStyled>
);

export const query = graphql`
  query ApplicationPageQuery($id: String) {
    contentfulPosition(id: { eq: $id }) {
      slug
      title
      location
      content {
        raw
      }
      contacts {
        emailAddress
      }
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
    allContentfulFaq {
      nodes {
        id
        question
        answer {
          raw
        }
      }
    }
  }
`;

export default ContentfulCase;
