/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import ApplicationFormStyled from '@/components/styled/ApplicationFormStyled';
import { PositionProp } from '@/types';
import { applyJob } from '@/http-request/application-request';
import Reveal from '@/components/reveal/Reveal';

// eslint-disable-next-line no-shadow
enum Form {
  SUCCESS,
  FAILED,
  NORMAL,
}

interface IApplicationFormProps {
  position: PositionProp;
}
const ApplicationForm: React.FC<IApplicationFormProps> = ({ position }) => {
  const [formState, setFormState] = useState<Form>(Form.NORMAL);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    if (formState !== Form.SUCCESS) {
      Promise.all([applyJob(form)])
        .then((res) => {
          if (
            res[0].data?.data?.statusCode &&
            res[0]?.data?.data?.statusCode === 400
          ) {
            setFormState(Form.FAILED);
          } else {
            setFormState(Form.SUCCESS);
            e.target.reset();
          }
        })
        .catch(() => {
          setFormState(Form.FAILED);
        });
    }
  };

  return (
    <ApplicationFormStyled className="application-form" id="application-form">
      <Reveal className="container">
        <form
          onSubmit={onSubmitHandler}
          id="formApplication"
          method="post"
          className="i-amphtml-form"
          data-form-type="contact"
          encType="multipart/form-data"
        >
          {formState === Form.SUCCESS ? (
            <div
              submit-success=""
              className="submit-success"
              role="alert"
              aria-label="rendered-message-amp-form-0"
              aria-live="assertive"
            >
              <img
                src="/assets/images/submit-success.svg"
                width={133}
                height={159}
                className="i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                style={{ width: `133px`, height: `159px` }}
                alt="Success"
              />
              <p className="h2">Thank you for your application!</p>
              <Link className="link" to="/">
                IONA Home Page
              </Link>
            </div>
          ) : (
            <fieldset>
              <input type="hidden" name="position" value={position.title} />
              <input type="hidden" name="title" value={position.title} />
              <input type="hidden" name="location" value={position.location} />
              <h2>Apply</h2>
              <p className="body-1">We will respond to you within 2 weeks</p>
              <hr />
              <div className="row job-details">
                <div className="col-2 col-t-1 col-m-1 col-job-title">
                  <span className="body-1 field">Job title</span>
                  <span className="extra-body value">{position.title}</span>
                </div>
                <div className="col-2 col-t-1 col-m-1 col-location">
                  <span className="body-1 field">Location</span>
                  <span className="extra-body value">{position.location}</span>
                </div>
              </div>
              <hr />
              <div className="row form">
                <div className="col-2 col-t-2 col-m-2 col-name">
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    data-kwimpalastatus="alive"
                    data-kwimpalaid="1632481833620-0"
                    data-form-type="name"
                  />
                </div>
                <div className="col-2 col-t-2 col-m-2 col-email">
                  <label htmlFor="email">Email</label>
                  <input id="email" name="email" type="text" required />
                </div>
                <div className="col-2 col-t-2 col-m-2 col-phone">
                  <label htmlFor="phone">Phone number</label>
                  <input id="phone" name="phone" type="text" />
                </div>
                <div className="col-2 col-t-2 col-m-2 col-links">
                  <label htmlFor="link">
                    Any links you’d like to share (LinkedIn, Github etc.)?
                  </label>
                  <input id="link" name="linkedin" type="text" />
                </div>
                <div className="col-4 col-t-2 col-m-2 col-comment">
                  <label htmlFor="introduction">
                    Tell us why’d you like to work at IONA
                  </label>
                  <textarea
                    id="introduction"
                    data-dashlane-rid="4aaaef5e4829c00c"
                    data-form-type="other"
                  />
                </div>
              </div>
              <div className="row attach-submit">
                <div className="col-2 col-t-1 col-m-2">
                  <label htmlFor="file" className="attachment">
                    Attach a file if you want (max 2MB)
                  </label>
                  <input id="file" type="file" name="file" />
                </div>
                <div className="col-2 col-t-1 col-m-2">
                  <button
                    type="submit"
                    className="wide apply-button"
                    data-form-type="other"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </fieldset>
          )}
        </form>
      </Reveal>
    </ApplicationFormStyled>
  );
};

export default ApplicationForm;
